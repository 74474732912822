.filter-card .ant-card-body {
  display: flex;
  flex-direction: column;
  width: 60%;
}

.filters {
  display: flex;
  flex-wrap: wrap;
}

.input-filters {
  flex: 1;
  margin-right: 10px;
}

.input-filters .ant-input {
  margin: 8px 0px;
}

.date-pickers {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.date-pickers .date-picker-input {
  margin: 8px 0px;
}

.price-filter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.price-filter span {
  flex: 1;
}

.price-filter span:first-child {
  margin-right: 20px;
}

.filter-button {
  margin-top: 10px;
}
